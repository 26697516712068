import React from "react"
import { Link } from "react-router-dom"
import AvatarIcon from "../AvatarIcon"

const SiteNav = ({ user }) => {
	return (
		<div className="siteNav">
			<div className="greeting">
				{user?.f_name && (
					<>
						<span>Hello,</span>
						{user.f_name}
					</>
				)}
			</div>
			<Link to={`../${user.username}`}>
				<AvatarIcon url={user?.avatar} size={50} />
			</Link>
		</div>
	)
}

export default SiteNav
