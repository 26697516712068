import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import toTitleCase from "../../helpers/toTitleCase"

export default function NewDrink() {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)
	const [type, setType] = useState("lager")
	const [typeResults, setTypeResults] = useState(false)
	const [alcoholPercentage, setAlcoholPercentage] = useState("")
	const [alcoholPercentageError, setAlcoholPercentageError] = useState(false)

	let types = [
		"barley wine",
		"bitter",
		"india pale ale",
		"lager",
		"pale ale",
		"pilsner",
		"stout",
	]

	function handleOnSubmit(e) {
		e.preventDefault()

		if (!name.length > 0) {
			setNameError(true)
			return
		}
		if (!alcoholPercentage.length > 0) {
			setAlcoholPercentageError(true)
			return
		}

		let newDrink = {
			name: toTitleCase(name),
			type: type,
			alcohol_percentage: alcoholPercentage,
		}

		updateDatabase(newDrink)

		navigate(-1)
	}

	async function updateDatabase(newDrink) {
		const { error } = await supabase.from("drinks").insert(newDrink)
		if (error) {
			console.error(error)
		}
	}

	return (
		<div>
			<h3>New drink</h3>
			<form onSubmit={handleOnSubmit}>
				<fieldset>
					<label>Name</label>
					<input
						type="text"
						value={name || ""}
						onSelect={() => setNameError(false)}
						onChange={(e) => {
							setName(e.target.value)
						}}
					/>
					{nameError && <p className="error">Please enter a valid name</p>}
				</fieldset>
				<fieldset>
					<label>Type</label>
					<div className="dropdown">
						<input
							className="input"
							type="text"
							value={type || "lager"}
							onChange={(e) => setType(e.target.value)}
							onSelect={() => setTypeResults(true)}
						/>
						<FontAwesomeIcon icon="fas fa-caret-down" />
					</div>
					{typeResults && (
						<div className="search-results">
							<ul>
								{types.map((type, i) => (
									<li
										key={i}
										onClick={() => {
											setType(type)
											setTypeResults(false)
										}}>
										<div>{type}</div>
									</li>
								))}
							</ul>
						</div>
					)}
				</fieldset>
				<fieldset>
					<label>Alcohol Percentage</label>
					<input
						type="number"
						step={0.1}
						value={alcoholPercentage || ""}
						onSelect={() => setAlcoholPercentageError(false)}
						onChange={(e) => setAlcoholPercentage(e.target.value)}
					/>
					{alcoholPercentageError && (
						<p className="error">Please enter a valid alcohol percentage</p>
					)}
				</fieldset>
				<fieldset>
					<button className="button">Submit</button>
				</fieldset>
			</form>
		</div>
	)
}
