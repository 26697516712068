import React, { useEffect } from "react"
import { useLoaderData } from "react-router-dom"
import LocationNav from "../../components/profile/LocationNav"
import MapStatic from "../../components/MapStatic"
import Socials from "../../components/Socials"
import copy from "copy-to-clipboard"
import { APP_NAME } from "../.."

export default function LocationProfile() {
	const data = useLoaderData()
	const location = data.data
	const coordinates = data.coordinates
	const lng = coordinates[0]
	const lat = coordinates[1]

	const copyToClipboard = (e) => {
		copy(e.target.innerText)
		alert(`Copied "${e.target.innerText}"`)
	}

	useEffect(() => {
		document.title = `${location.name} • ${APP_NAME}`
	}, [location])

	return (
		<>
			<LocationNav location={location} />
			<div className="profile-location main-content">
				<div className="container">
					<MapStatic lng={lng} lat={lat} zoom={15} />
					<main>
						<p>{location.address}</p>
						{location.wifi && (
							<div className="wifi">
								<p>
									<b>WiFi password:</b>{" "}
									<span onClick={copyToClipboard}>{location.wifi}</span>
									<br />
									<small>(click password to copy)</small>
								</p>
							</div>
						)}
						<Socials
							instagram={location.instagram}
							twitter={location.twitter}
							website={location.website}
						/>
					</main>
				</div>
			</div>
		</>
	)
}
