import React, { useEffect, useState } from "react"

import {
	addMonths,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	getDate,
	isSameDay,
	isSameMonth,
	startOfMonth,
	startOfWeek,
	subMonths,
} from "date-fns"

function DateElement({ date, setDate, selectedDate, currentDate, setShow }) {
	return (
		<button
			className={`date${
				!isSameMonth(date, currentDate) ? " date-picker-other-month-date" : ""
			}${isSameDay(date, selectedDate) ? " selected" : ""}`}
			onClick={() => {
				setDate(date)
				setShow(false)
			}}>
			{getDate(date)}
		</button>
	)
}

export default function DatePicker() {
	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState()
	const [currentDate, setCurrentDate] = useState(new Date())
	const [label, setLabel] = useState("")
	const [headerLabel, setHeaderLabel] = useState("")
	const [dates, setDates] = useState([])

	function handleOnClick() {
		setShow(true)
		setCurrentDate(selected)
		setupDatePicker(selected)
	}

	function setDate(date) {
		setLabel(format(date, "MMMM do, yyyy"))
		setSelected(date)
	}

	function setupDatePicker(selectedDate) {
		setHeaderLabel(format(currentDate, "MMMM - yyyy"))
		setupDates(selectedDate)
	}

	function setupDates(selectedDate) {
		setSelected(selectedDate)
		const firstWeekStart = startOfWeek(startOfMonth(currentDate))
		const lastWeekEnd = endOfWeek(endOfMonth(currentDate))
		setDates(eachDayOfInterval({ start: firstWeekStart, end: lastWeekEnd }))
	}

	function handlePrevMonthButton() {
		setCurrentDate(subMonths(currentDate, 1))
		setupDatePicker(selected)
	}
	function handleNextMonthButton() {
		setCurrentDate(addMonths(currentDate, 1))
		setupDatePicker(selected)
	}

	useEffect(() => {
		setDate(new Date())
	}, [])

	useEffect(() => {
		setCurrentDate(selected)
	}, [selected])

	return (
		<>
			<button
				className="button date-picker-button"
				onClick={() => handleOnClick()}>
				{label}
			</button>
			{show && (
				<>
					<div className="date-picker-overlay" onClick={() => setShow(false)} />
					<div className="date-picker-container">
						<div className="date-picker">
							<div className="date-picker-header">
								<button
									className="prev-month-button month-button"
									onClick={() => handlePrevMonthButton()}>
									&larr;
								</button>
								<div className="current-month">{headerLabel}</div>
								<button
									className="next-month-button month-button"
									onClick={() => handleNextMonthButton()}>
									&rarr;
								</button>
							</div>
							<div className="date-picker-grid-header date-picker-grid">
								<div>Sun</div>
								<div>Mon</div>
								<div>Tue</div>
								<div>Wed</div>
								<div>Thu</div>
								<div>Fri</div>
								<div>Sat</div>
							</div>
							<div className="date-picker-grid-dates date-picker-grid">
								{dates.map((date, i) => (
									<DateElement
										key={i}
										date={date}
										setDate={setDate}
										selectedDate={selected}
										setShow={setShow}
										currentDate={currentDate}
									/>
								))}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
