export default function separateOut(arr) {
	if (!arr.length) {
		return []
	}
	const res = {}
	const keys = Object.keys(arr[0])
	keys.forEach((key) => {
		arr.forEach((el) => {
			if (res.hasOwnProperty(key)) {
				res[key].push(el[key])
			} else {
				res[key] = [el[key]]
			}
		})
	})
	return res
}
