import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"

function ResultPlace({ place, handleOnClick }) {
	return (
		<li onClick={() => handleOnClick(place)}>
			<div className="result" data-type="place">
				<div className="icon">
					<FontAwesomeIcon icon={`fas fa-shop`} />
				</div>
				<div className="text">
					<div className="name">{place.name}</div>
					<div className="address">{place.address}</div>
				</div>
			</div>
		</li>
	)
}

export default function ExplorePlaces() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	const [recentPlaces, setRecentPlaces] = useState()

	const { locations } = useLoaderData()
	const places = locations

	const [allPlaces, setAllPlaces] = useState([])
	const [filteredPlaces, setFilteredPlaces] = useState([])

	useEffect(() => {
		if (storedSearches && storedSearches.recent_places) {
			setRecentPlaces(storedSearches.recent_places)
		} else {
			setRecentPlaces([])
		}
	}, [storedSearches])

	useEffect(() => {
		if (recentPlaces) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_places: recentPlaces,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentPlaces])

	async function handleOnClick(place) {
		let filteredRecentPlaces = [...recentPlaces]
		filteredRecentPlaces = filteredRecentPlaces.filter(
			(item) => item.id !== place.id
		)
		if (filteredRecentPlaces > 4) {
			filteredRecentPlaces.pop()
		}

		await setRecentPlaces([...filteredRecentPlaces, place])
		await navigate(`${ROUTES.LOCATION}/${place.id}`)
	}

	useEffect(() => {
		places && places.length > 0 && setAllPlaces(places)
	}, [places])

	useEffect(() => {
		let results = allPlaces.filter((item) =>
			item?.name.toLowerCase().includes(query?.toLowerCase())
		)
		setFilteredPlaces(results)
	}, [allPlaces, query])

	return (
		<>
			{query && query.length > 0 ? (
				<ul className="search-results">
					{filteredPlaces &&
						filteredPlaces.length > 0 &&
						filteredPlaces.map((place) => (
							<ResultPlace
								key={place.id}
								place={place}
								handleOnClick={handleOnClick}
							/>
						))}
				</ul>
			) : (
				<>
					<h3>Recent Location Searches</h3>
					<ul className="search-results reversed">
						{recentPlaces &&
							recentPlaces.length > 0 &&
							recentPlaces.map((place) => (
								<ResultPlace
									key={place.id}
									place={place}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
