import React, { useState } from "react"
import {
	Form,
	useLoaderData,
	useNavigate,
	useNavigation,
} from "react-router-dom"
import LocationNav from "../../components/profile/LocationNav"
import { supabase } from "../../lib/supabaseClient"

export default function EditLocation() {
	const location = useLoaderData()
	const navigate = useNavigate()
	const navigation = useNavigation()
	const busy = navigation.state === "submitting"

	const [name, setName] = useState(location.name)
	const [type, setType] = useState(location.type)
	const [address, setAddress] = useState(location.address)
	const [wifi, setWifi] = useState(location.wifi)
	const [website, setWebsite] = useState(location.website)
	const [instagram, setInstagram] = useState(location.instagram)
	const [twitter, setTwitter] = useState(location.twitter)

	const handleSubmit = async (e) => {
		e.preventDefault()

		let updated_at = new Date()

		const { error } = await supabase
			.from("locations")
			.update({
				id: location.id,
				name,
				type,
				address,
				wifi,
				website,
				instagram,
				twitter,
				updated_at,
			})
			.eq("id", location.id)

		if (error) {
			console.error(error)
		} else {
			navigate(-1)
		}
	}

	return (
		<div className="profile-location">
			<div className="container">
				<LocationNav location={location} />
				<main>
					<Form method="post" onSubmit={handleSubmit}>
						<fieldset>
							<label htmlFor="name">Location name:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Name"
								type="text"
								name="name"
								value={name || ""}
								onChange={(e) => setName(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="type">Location type:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Type"
								type="text"
								name="type"
								value={type || ""}
								onChange={(e) => setType(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="address">Location address:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Address"
								type="text"
								name="address"
								value={address || ""}
								onChange={(e) => setAddress(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="wifi">WiFi Password:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="WiFi password"
								type="text"
								name="wifi"
								value={wifi || ""}
								onChange={(e) => setWifi(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="website">Website:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Website"
								type="text"
								name="website"
								value={website || ""}
								onChange={(e) => setWebsite(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="instagram">Instagram:</label>
							<div className="row">
								<div>
									<b>https://instagram.com/</b>
								</div>
								<input
									autoComplete="off"
									className="input"
									placeholder="instagram"
									type="text"
									name="instagram"
									value={instagram || ""}
									onChange={(e) => setInstagram(e.target.value)}
								/>
							</div>
						</fieldset>
						<fieldset>
							<label htmlFor="twitter">Twitter:</label>
							<div className="row">
								<div>
									<b>https://twitter.com/</b>
								</div>
								<input
									autoComplete="off"
									className="input"
									placeholder="twitter"
									type="text"
									name="twitter"
									value={twitter || ""}
									onChange={(e) => setTwitter(e.target.value)}
								/>
							</div>
						</fieldset>
						<button className="button" disabled={busy}>
							{busy ? "Updating..." : "Update"}
						</button>
					</Form>
				</main>
			</div>
		</div>
	)
}
