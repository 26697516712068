import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

const HistoryNav = () => {
	const navigate = useNavigate()
	function handleCancel() {
		navigate(-1)
	}

	return (
		<div className="history-nav">
			<div className="cancel" onClick={(e) => handleCancel(e)}>
				<FontAwesomeIcon icon={`fas fa-arrow-left`} />
			</div>
			<div className="title">{`History`}</div>
			<div className="menu">
				{/* <FontAwesomeIcon icon={`fas fa-ellipsis-vertical`} /> */}
			</div>
		</div>
	)
}

export default HistoryNav
