import React from "react"

const Spinner = ({ size, fullPage = true, white = false }) => {
	return (
		<div className={fullPage ? "spinnerW" : "" || white ? "white" : ""}>
			<div className="dot-spinner">
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
			</div>
		</div>
	)
}

export default Spinner
