import { format, fromUnixTime } from "date-fns"
import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { APP_NAME } from ".."
import AvatarIcon from "../components/AvatarIcon"
import DateNav from "../components/profile/DateNav"
import Stats from "../components/profile/Stats"
import ProfileNav from "../components/profile/ProfileNav"
import Total from "../components/profile/Total"
import findTotalSpent from "../helpers/findTotalSpent"
import mergeArrays from "../helpers/mergeArrays"
import separateOut from "../helpers/seperateOut"
// import * as ROUTES from "../constants/routes"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Profile() {
	const user = useOutletContext()
	// const navigate = useNavigate()

	let today = new Date()
	today = today.getTime()

	const [currentDate, setCurrentDate] = useState(today)

	// Stats
	const [amountSpent, setAmountSpent] = useState(0)
	const [amountSpentToday, setAmountSpentToday] = useState(0)
	const [amountDrank, setAmountDrank] = useState(0)
	const [amountDrankToday, setAmountDrankToday] = useState(0)
	const [attendedVenues, setAttendedVenues] = useState([])
	const [attendedVenuesToday, setAttendedVenuesToday] = useState([])
	const [metUpFriends, setMetUpFriends] = useState([])
	const [metUpFriendsToday, setMetUpFriendsToday] = useState([])

	useEffect(() => {
		let totalVisits = user.visits !== null ? user.visits : []
		let totalVisitsToday = []
		totalVisits.forEach((item) => {
			if (
				[format(currentDate, "P")].includes(
					format(fromUnixTime(item.timestamp), "P")
				)
			) {
				totalVisitsToday.push(item)
			}
		})

		// Amount Spent
		setAmountSpent(
			totalVisits.length > 0
				? findTotalSpent(totalVisits, "drinks", "price")
				: 0
		)

		// Amount Spent Today
		setAmountSpentToday(
			totalVisitsToday.length > 0
				? findTotalSpent(totalVisitsToday, "drinks", "price")
				: 0
		)

		// Amount Drank
		setAmountDrank(
			totalVisits.length > 0 ? findTotalSpent(totalVisits, "drinks", "ml") : 0
		)

		// Amount Drank Today
		setAmountDrankToday(
			totalVisitsToday.length > 0
				? findTotalSpent(totalVisitsToday, "drinks", "ml")
				: 0
		)

		// Attended Venues
		setAttendedVenues([...new Set(separateOut(totalVisits).location)])

		// Attended Venues Today
		setAttendedVenuesToday([...new Set(separateOut(totalVisitsToday).location)])

		// Met Up Friends
		setMetUpFriends(
			totalVisits.length > 0 ? mergeArrays(totalVisits, "attendees", true) : []
		)

		// Met Up Friends Today
		setMetUpFriendsToday(
			totalVisitsToday.length > 0
				? mergeArrays(totalVisitsToday, "attendees", true)
				: []
		)
	}, [currentDate, user.visits])

	useEffect(() => {
		document.title = `@${user.username} • ${APP_NAME}`
	}, [user.username])

	// function editProfile() {
	// 	navigate(ROUTES.EDIT_PROFILE)
	// }

	return (
		<>
			<ProfileNav user={user} />
			<div className="profile-page main-content">
				<div className="container">
					<header>
						<AvatarIcon url={user.avatar} size={100} />
						<div className="name">{`${user.f_name} ${user.l_name}`}</div>
						{/* <div className="actions">
							<button className="button" onClick={editProfile}>
								<FontAwesomeIcon icon="fas fa-pen" /> Edit Profile
							</button>
							<button className="button">
								<FontAwesomeIcon icon="fas fa-share" /> Share Profile
							</button>
						</div> */}
					</header>
					<main>
						<DateNav
							currentDate={currentDate}
							setCurrentDate={setCurrentDate}
							today={today}
						/>
						<Stats
							amountDrank={amountDrankToday}
							amountSpent={amountSpentToday}
							attendedVenues={attendedVenuesToday}
							metUpFriends={metUpFriendsToday}
							currentDate={currentDate}
							comments={true}
						/>
						<Total />
						<Stats
							amountDrank={amountDrank}
							amountSpent={amountSpent}
							attendedVenues={attendedVenues}
							metUpFriends={metUpFriends}
							comments={false}
						/>
					</main>
				</div>
			</div>
		</>
	)
}
