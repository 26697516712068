import React from "react"
import m1 from "../assets/avatars/m1.jpg"
import m2 from "../assets/avatars/m2.jpg"
import m3 from "../assets/avatars/m3.jpg"
import m4 from "../assets/avatars/m4.jpg"
import m5 from "../assets/avatars/m5.jpg"
import f1 from "../assets/avatars/f1.jpg"
import f2 from "../assets/avatars/f2.jpg"
import f3 from "../assets/avatars/f3.jpg"
import f4 from "../assets/avatars/f4.jpg"
import f5 from "../assets/avatars/f5.jpg"

export default function AvatarIcon({ url, size = 50, name, centered }) {
	let avatarUrl
	switch (url) {
		case 1:
			avatarUrl = f1
			break
		case 2:
			avatarUrl = m2
			break
		case 3:
			avatarUrl = f3
			break
		case 4:
			avatarUrl = m4
			break
		case 5:
			avatarUrl = f5
			break
		case 6:
			avatarUrl = m1
			break
		case 7:
			avatarUrl = f2
			break
		case 8:
			avatarUrl = m3
			break
		case 9:
			avatarUrl = f4
			break
		default:
			avatarUrl = m5
	}

	let styles = {
		height: size,
		width: size,
		marginLeft: centered ? "auto" : "",
		marginRight: centered ? "auto" : "",
	}

	return (
		<div className="avatarIcon" style={styles}>
			<img
				src={avatarUrl}
				alt={url ? "Avatar" : "No image"}
				className="avatar image"
			/>
			{name && <div className="name">{name}</div>}
		</div>
	)
}
