import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import {
	useLoaderData,
	useNavigate,
	useNavigation,
	useOutletContext,
} from "react-router-dom"
import Spinner from "../../components/Spinner"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"

function ResultDrink({ drink, handleOnClick }) {
	return (
		<li onClick={() => handleOnClick(drink)}>
			<div className="result" data-type="drink">
				<div className="icon">
					<FontAwesomeIcon icon={`fas fa-beer`} />
				</div>
				<div className="text">
					<div className="name">{drink.name}</div>
					<div className="type">
						{drink.type} • {drink.alcohol_percentage}%
					</div>
				</div>
			</div>
		</li>
	)
}

export default function ExploreDrinks() {
	const navigate = useNavigate()
	const navigation = useNavigation()
	const { query, storedSearches } = useOutletContext()

	const [recentDrinks, setRecentDrinks] = useState()

	const { drinks } = useLoaderData()

	const [allDrinks, setAllDrinks] = useState([])
	const [filteredDrinks, setFilteredDrinks] = useState([])

	useEffect(() => {
		if (storedSearches && storedSearches.recent_drinks) {
			setRecentDrinks(storedSearches.recent_drinks)
		} else {
			setRecentDrinks([])
		}
	}, [storedSearches])

	useEffect(() => {
		if (recentDrinks) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_drinks: recentDrinks,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentDrinks])

	async function handleOnClick(drink) {
		let filteredRecentDrinks = [...recentDrinks]
		filteredRecentDrinks = filteredRecentDrinks.filter(
			(item) => item.id !== drink.id
		)
		if (filteredRecentDrinks > 4) {
			filteredRecentDrinks.pop()
		}

		await setRecentDrinks([...filteredRecentDrinks, drink])
		await navigate(`${ROUTES.DRINK}/${drink.id}`)
	}

	useEffect(() => {
		drinks && drinks.length > 0 && setAllDrinks(drinks)
	}, [drinks])

	useEffect(() => {
		let results = allDrinks.filter((item) =>
			item?.name.toLowerCase().includes(query?.toLowerCase())
		)
		setFilteredDrinks(results)
	}, [allDrinks, query])

	return (
		<>
			{query && query.length > 0 ? (
				navigation.state === "loading" ? (
					<Spinner />
				) : (
					<ul className="search-results">
						{filteredDrinks &&
							filteredDrinks.length > 0 &&
							filteredDrinks.map((drink) => (
								<ResultDrink
									key={drink.id}
									drink={drink}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				)
			) : (
				<>
					<h3>Recent Beer Searches</h3>
					<ul className="search-results reversed">
						{recentDrinks &&
							recentDrinks.length > 0 &&
							recentDrinks.map((drink) => (
								<ResultDrink
									key={drink.id}
									drink={drink}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
