import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import * as ROUTES from "../constants/routes"
import AvatarIcon from "./AvatarIcon"

const FooterNav = ({ user }) => {
	return (
		<div className="footer-nav">
			<div>
				<NavLink to={ROUTES.DASHBOARD} className={"link"}>
					<FontAwesomeIcon icon={`fas fa-house`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`${ROUTES.EXPLORE}`} className={"link"}>
					<FontAwesomeIcon icon={`fas fa-search`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`${ROUTES.HISTORY}`} className={"link"}>
					<FontAwesomeIcon icon={`fas fa-clock-rotate-left`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`../${user.username}`} className={"link"}>
					{/* <FontAwesomeIcon icon={`fas fa-user`} /> */}
					<AvatarIcon url={user.avatar} size={40} />
				</NavLink>
			</div>
		</div>
	)
}

export default FooterNav
