import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	addDays,
	format,
	fromUnixTime,
	getTime,
	// isFuture,
	isToday,
	isYesterday,
	subDays,
} from "date-fns"
import React, { useEffect, useState } from "react"
import {
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom"
import { APP_NAME } from "../.."
import BottomMenu from "../../components/BottomMenu"
import HistoryNav from "../../components/profile/HistoryNav"

export default function History() {
	const param = useParams()
	const user = useOutletContext()
	const navigate = useNavigate()

	let today = new Date()
	today = today.getTime()

	let paramDate
	if ("y" in param && "m" in param && "d" in param) {
		paramDate = getTime(new Date(param.y, param.m - 1, param.d))
	} else {
		paramDate = today
	}

	const [currentDate, setCurrentDate] = useState(paramDate)
	const [menuIsActive, setMenuIsActive] = useState(false)

	let links = [
		{
			label: "Edit",
			link: `./${param.y}/${param.m}/${param.d}/edit`,
		},
	]

	let visits = user.visits ? user.visits : []
	let visitsToday = []
	visits.forEach((item) => {
		if (
			[format(currentDate, "P")].includes(
				format(fromUnixTime(item.timestamp), "P")
			)
		) {
			visitsToday.push(item)
		}
	})

	function handleOnKeyDown(e) {
		// Left
		if (e.which === 37) {
			handlePrevDayButton()
		}
		// Right
		if (e.which === 39) {
			handleNextDayButton()
		}
	}
	window.onkeydown = (e) => handleOnKeyDown(e)

	function handlePrevDayButton() {
		let day = currentDate
		let prevDay = subDays(day, 1)
		setCurrentDate(prevDay)
		navigate(`${format(prevDay, "yyyy/MM/dd")}`, { replace: true })
	}

	function handleNextDayButton() {
		if (isToday(currentDate)) return
		let day = currentDate
		let nextDay = addDays(day, 1)
		setCurrentDate(nextDay)
		navigate(format(nextDay, "yyyy/MM/dd"), { replace: true })
	}

	function handleResetButton() {
		if (isToday(currentDate)) return
		setCurrentDate(today)
		navigate(format(today, "yyyy/MM/dd"), { replace: true })
	}

	function handleDateFormat(date) {
		if (isToday(date)) {
			return "Today"
		} else if (isYesterday(date)) {
			return "Yesterday"
		} else {
			return format(date, "E, d MMM")
		}
	}

	useEffect(() => {
		document.title = `History • ${APP_NAME}`
	}, [])

	return (
		<>
			<HistoryNav
				menuIsActive={menuIsActive}
				setMenuIsActive={setMenuIsActive}
			/>
			<div className="history-page main-content">
				<div className="container">
					<nav className="dateNav">
						<div className="prev" onClick={() => handlePrevDayButton()}>
							<FontAwesomeIcon icon={`fas fa-arrow-left`} />
						</div>
						<div className="date" onClick={() => handleResetButton()}>
							{handleDateFormat(currentDate)}
						</div>
						<div
							className={`next${isToday(currentDate) ? " hide" : ""}`}
							onClick={() => handleNextDayButton()}>
							<FontAwesomeIcon icon={`fas fa-arrow-right`} />
						</div>
					</nav>

					<main>
						<div className="visits">
							{visitsToday && visitsToday.length > 0 ? (
								<Outlet
									context={{
										visits: visitsToday,
									}}
								/>
							) : (
								<p>No visits...</p>
							)}
						</div>
					</main>
				</div>
				{/* <BottomMenu
					menuIsActive={menuIsActive}
					setMenuIsActive={setMenuIsActive}
					links={links}
				/> */}
			</div>
		</>
	)
}
