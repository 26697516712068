import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useLoaderData, useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."
import DrinkNav from "../../components/profile/DrinkNav"
import { supabase } from "../../lib/supabaseClient"

export default function DrinkProfile() {
	const drink = useLoaderData()
	const user = useOutletContext()

	const [isFavourited, setIsFavourited] = useState(false)
	const [count, setCount] = useState(0)

	useEffect(() => {
		drink.favourite_count ? setCount(drink.favourite_count) : setCount(0)
	}, [drink])

	async function handleOnFavourited() {
		handleOnLikeClick()
		if (!isFavourited) {
			let userFavouriteDrinks = new Set(user.drinks)
			userFavouriteDrinks.push(drink.id)
			const { error } = await supabase
				.from("profiles")
				.update({ drinks: userFavouriteDrinks })
				.eq("id", user.id)

			if (error) {
				console.error(error)
			} else {
				setIsFavourited(true)
			}

			let newCount = count + 1

			const { err } = await supabase
				.from("drinks")
				.update({ favourite_count: newCount })
				.eq("id", drink.id)

			if (err) {
				console.error(err)
			} else {
				setCount(count + 1)
			}
		} else {
			let userFavouriteDrinks = user.drinks

			let filteredDrinks = userFavouriteDrinks.filter((item) => {
				return item !== drink.id.toString()
			})
			const { error } = await supabase
				.from("profiles")
				.update({ drinks: filteredDrinks })
				.eq("id", user.id)

			if (error) {
				console.error(error)
			} else {
				setIsFavourited(false)
			}

			let newCount = count - 1

			const { err } = await supabase
				.from("drinks")
				.update({ favourite_count: newCount })
				.eq("id", drink.id)

			if (err) {
				console.error(err)
			} else {
				setCount(count - 1)
			}
		}
	}

	const [motion, setMotion] = useState(true)

	function handleOnLikeClick() {
		setMotion(true)
		setTimeout(() => {
			setMotion(false)
		}, 800)
	}

	useEffect(() => {
		if (user.drinks && user.drinks.length > 0) {
			if (user.drinks.includes(drink.id.toString())) {
				setIsFavourited(true)
			}
		}
	}, [user, drink])

	useEffect(() => {
		document.title = `${drink.name} • ${APP_NAME}`
	}, [drink])

	return (
		<div className="profile-drink main-content">
			<div className="hero">
				<DrinkNav />
			</div>
			<main>
				<div className="container">
					<header>
						<div className="type">{drink.type}</div>
						<div className="title">{drink.name}</div>
						<div className="pills">
							<div className="favourite">
								<span className="icon">
									<div
										className={`like-button ${isFavourited ? " active" : ""}`}
										onClick={handleOnFavourited}>
										<FontAwesomeIcon icon="fas fa-heart" />
										<i className={motion ? "count-motion" : ""}>{count}</i>
										<span className={motion ? "bubble-motion" : ""} />
									</div>
								</span>
								<span>{count}</span>
							</div>
							<div className="rating">
								<span className="icon">
									<FontAwesomeIcon icon="fas fa-star" />
								</span>
								<span>4.2</span>
							</div>
						</div>
					</header>
					<div className="description">
						<div className="percentage">
							<span className="label">Percentage: </span>{" "}
							{drink.alcohol_percentage}%
						</div>
						<div className="price">
							<span className="label">Average Price: </span> £
							{drink.alcohol_percentage}
						</div>
						<div className="locations">
							Found in <b>57</b> locations
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className="map-container">
					<div className="map"></div>
				</div>
			</footer>
		</div>
	)
}
