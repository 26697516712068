import React from "react"
import BeersIcon from "./Beers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Stat = ({ icon, number, title }) => {
	return (
		<div className="stat">
			<div className="icon">
				<FontAwesomeIcon icon={`fas fa-${icon}`} />
			</div>
			<>
				<div className="title">{title}</div>
				<div className="number">{number}</div>
			</>
		</div>
	)
}

const Stats = ({
	amountDrank,
	amountSpent,
	attendedVenues,
	metUpFriends,
	currentDate,
	comments,
}) => {
	return (
		<div className="statsW">
			{/* beers drank */}
			<BeersIcon
				ml={amountDrank}
				currentDate={currentDate}
				comments={comments}
			/>

			<div className="stats">
				{/* money spent */}
				<Stat
					icon="sack-dollar"
					title="Spent"
					number={`${new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "EUR",
					}).format(amountSpent)}`}
				/>
				{/* venues visited */}

				<Stat
					icon="store"
					title="Attended"
					number={
						attendedVenues.length > 0
							? `${attendedVenues.length} venue${
									attendedVenues.length === 1 ? "" : "s"
							  }`
							: "Nowhere"
					}
					data={attendedVenues}
				/>

				{/* friends met */}

				<Stat
					icon="user-group"
					title={"Met up with"}
					number={
						metUpFriends.length > 0
							? `${metUpFriends.length} friend${
									metUpFriends.length === 1 ? "" : "s"
							  }`
							: "No one"
					}
					data={metUpFriends}
				/>
			</div>
		</div>
	)
}

export default Stats
