export const DASHBOARD = "/dashboard"

export const NOT_FOUND = "/not-found"

export const LOGIN = "/login"
export const UPDATE_PASSWORD = "/update-password"
export const SIGN_UP = "/sign-up"

export const PROFILE = "/p"
export const LOCATION = "/explore/places"
export const DRINK = "/explore/beers"

export const EDIT_PROFILE = "/accounts/edit"
export const HISTORY = "/accounts/history"

export const EXPLORE = "/explore"

export const RANKINGS = "/rankings"
