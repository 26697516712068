import { format, fromUnixTime } from "date-fns"
import React, { useEffect, useState } from "react"
import { useLoaderData, useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."
import AvatarIcon from "../../components/AvatarIcon"
import Stats from "../../components/profile/Stats"
import Total from "../../components/profile/Total"
import findTotalSpent from "../../helpers/findTotalSpent"
import mergeArrays from "../../helpers/mergeArrays"
import separateOut from "../../helpers/seperateOut"
import UserProfileNav from "../../components/profile/UserProfileNav"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { supabase } from "../../lib/supabaseClient"
import Spinner from "../../components/Spinner"

export default function ProfileUser() {
	const user = useLoaderData()
	const me = useOutletContext()

	const [meFriends, setMeFriends] = useState([])
	const [meFriendsLoader, setMeFriendsLoader] = useState(false)

	useEffect(() => {
		let friends = me && me.friends ? me.friends : []
		setMeFriends(friends)
	}, [me])

	let today = new Date()
	today = today.getTime()

	const [currentDate, setCurrentDate] = useState(today)

	// Stats
	const [amountSpent, setAmountSpent] = useState(0)
	const [amountSpentToday, setAmountSpentToday] = useState(0)
	const [amountDrank, setAmountDrank] = useState(0)
	const [amountDrankToday, setAmountDrankToday] = useState(0)
	const [attendedVenues, setAttendedVenues] = useState([])
	const [attendedVenuesToday, setAttendedVenuesToday] = useState([])
	const [metUpFriends, setMetUpFriends] = useState([])
	const [metUpFriendsToday, setMetUpFriendsToday] = useState([])

	useEffect(() => {
		let totalVisits = user.visits !== null ? user.visits : []
		let totalVisitsToday = []
		totalVisits.forEach((item) => {
			if (
				[format(currentDate, "P")].includes(
					format(fromUnixTime(item.timestamp), "P")
				)
			) {
				totalVisitsToday.push(item)
			}
		})

		// Amount Spent
		setAmountSpent(
			totalVisits.length > 0
				? findTotalSpent(totalVisits, "drinks", "price")
				: 0
		)

		// Amount Spent Today
		setAmountSpentToday(
			totalVisitsToday.length > 0
				? findTotalSpent(totalVisitsToday, "drinks", "price")
				: 0
		)

		// Amount Drank
		setAmountDrank(
			totalVisits.length > 0 ? findTotalSpent(totalVisits, "drinks", "ml") : 0
		)

		// Amount Drank Today
		setAmountDrankToday(
			totalVisitsToday.length > 0
				? findTotalSpent(totalVisitsToday, "drinks", "ml")
				: 0
		)

		// Attended Venues
		setAttendedVenues([...new Set(separateOut(totalVisits).location)])

		// Attended Venues Today
		setAttendedVenuesToday([...new Set(separateOut(totalVisitsToday).location)])

		// Met Up Friends
		setMetUpFriends(
			totalVisits.length > 0 ? mergeArrays(totalVisits, "attendees", true) : []
		)

		// Met Up Friends Today
		setMetUpFriendsToday(
			totalVisitsToday.length > 0
				? mergeArrays(totalVisitsToday, "attendees", true)
				: []
		)
	}, [currentDate, user.visits])

	useEffect(() => {
		document.title = `@${user.username} • ${APP_NAME}`
	}, [user.username])

	async function updateMeFriends(friends) {
		const { error } = await supabase
			.from("profiles")
			.update({ friends: friends })
			.eq("username", me.username)
		if (error) {
			console.error(error)
		}
	}

	function addFriend(username) {
		let updatedMeFriends = [...meFriends]
		updatedMeFriends.push(username)
		// setMeFriends(updatedMeFriends)
		setMeFriendsLoader(true)
		updateMeFriends(updatedMeFriends)
		window.location.reload()
	}

	function removeFriend(username) {
		let updatedFriends = meFriends.filter((item) => item !== username)
		// setMeFriends(updatedFriends)
		setMeFriendsLoader(true)
		updateMeFriends(updatedFriends)
		window.location.reload()
	}

	return (
		<>
			<UserProfileNav user={user} />

			<div className="profile-page main-content">
				<div className="container">
					<header>
						<AvatarIcon url={user.avatar} size={80} />
						<div className="name">{`${user.f_name} ${user.l_name}`}</div>
						<div className="actions">
							{meFriends.includes(user.username) ? (
								meFriendsLoader ? (
									<button className="button">
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}>
											<Spinner fullPage={false} white />
										</div>
									</button>
								) : (
									<button
										style={{ width: "50%" }}
										className="button"
										onClick={() => removeFriend(user.username)}>
										<FontAwesomeIcon icon={`fas fa-check`} /> Friends
									</button>
								)
							) : meFriendsLoader ? (
								<button className="button">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}>
										<Spinner fullPage={false} white />
									</div>
								</button>
							) : (
								<button
									style={{ width: "50%" }}
									className="button"
									onClick={() => addFriend(user.username)}>
									<FontAwesomeIcon icon={`fas fa-user-plus`} /> Add friend
								</button>
							)}
							{/* <button className="button" disabled>
								<FontAwesomeIcon icon="fas fa-paper-plane" /> Message
							</button> */}
							{/* <div style={{ width: "50%" }} className="friends">
								<b>Friends:</b> {user.friends.length}
							</div> */}
						</div>
					</header>
					<main>
						<Total />
						<Stats
							amountDrank={amountDrank}
							amountSpent={amountSpent}
							attendedVenues={attendedVenues}
							metUpFriends={metUpFriends}
							comments={false}
						/>
					</main>
				</div>
			</div>
		</>
	)
}
