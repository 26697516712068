import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { APP_NAME } from ".."
import * as ROUTES from "../constants/routes"
import { supabase } from "../lib/supabaseClient"
import Spinner from "../components/Spinner"

export default function UpdatePasswordPage() {
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [newPassword, setNewPassword] = useState("")

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			setLoading(true)

			const { error } = await supabase.auth.updateUser({
				password: newPassword,
			})

			if (error) throw error
		} catch (error) {
			setLoading(false)
		} finally {
			navigate(ROUTES.LOGIN)
		}
	}

	useEffect(() => {
		document.title = `Update Password • ${APP_NAME}`
	}, [])

	return (
		<div className="auth-page">
			<div className="container">
				{loading ? (
					<Spinner />
				) : (
					<>
						<h1>Password Reset</h1>
						<div>
							<form onSubmit={(e) => handleOnSubmit(e)}>
								<input
									type="password"
									placeholder="New password"
									required
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
								/>
								<button
									className="button"
									type="submit"
									disabled={newPassword.length > 3 ? false : true}>
									<span>Update</span>
								</button>
							</form>
							<p>
								<Link to={ROUTES.SIGN_UP}>Need an account?</Link>
							</p>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
