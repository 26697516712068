import React from "react"
import { Outlet, useLoaderData } from "react-router-dom"
// import PullToRefresh from "react-simple-pull-to-refresh"
import FooterNav from "../components/FooterNav"

export default function Root() {
	const user = useLoaderData()
	// function onRefresh() {
	// 	window.location.reload()
	// }

	return (
		// <PullToRefresh onRefresh={() => onRefresh()}>
		<div className="site-container">
			<Outlet context={user} />
			<FooterNav user={user} />
		</div>
		// </PullToRefresh>
	)
}
