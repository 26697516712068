import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom"
import AvatarIcon from "../../components/AvatarIcon"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"

function ResultUser({ user, handleOnClick }) {
	console.log(user)
	return (
		<li onClick={() => handleOnClick(user)}>
			{user && (
				<div className="result" data-type="user">
					{user.avatar ? (
						<AvatarIcon url={user.avatar} size={60} />
					) : (
						<div className="icon">
							<FontAwesomeIcon icon={`fas fa-user`} />
						</div>
					)}
					<div className="text">
						<div className="username">{user.username}</div>
						<div className="name">
							{user.f_name} {user.l_name}
						</div>
						{user.friends && user.friends.length > 0 && (
							<div className="followedby">
								Friends with {user.friends[user.friends.length - 1]}{" "}
								{user.friends.length > 1 && `+ ${user.friends.length - 1} more`}
							</div>
						)}
					</div>
				</div>
			)}
		</li>
	)
}

export default function ExploreUsers() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	const [recentUsers, setRecentUsers] = useState()

	const { profiles } = useLoaderData()
	const users = profiles

	const [allUsers, setAllUsers] = useState([])
	const [filteredUsers, setFilteredUsers] = useState([])

	useEffect(() => {
		if (storedSearches && storedSearches.recent_profiles) {
			setRecentUsers(storedSearches.recent_profiles)
		} else {
			setRecentUsers([])
		}
	}, [storedSearches])

	useEffect(() => {
		if (recentUsers) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_profiles: recentUsers,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentUsers])

	async function handleOnClick(user) {
		let filteredRecentUsers = [...recentUsers]
		filteredRecentUsers = filteredRecentUsers.filter(
			(item) => item.id !== user.id
		)
		if (filteredRecentUsers > 4) {
			filteredRecentUsers.pop()
		}

		await setRecentUsers([...filteredRecentUsers, user])
		await navigate(`${ROUTES.PROFILE}/${user.username}`)
	}

	useEffect(() => {
		users && users.length > 0 && setAllUsers(users)
	}, [users])

	useEffect(() => {
		let results = allUsers.filter((item) => {
			let name = item.f_name + " " + item.l_name
			return (
				item?.username
					.toLowerCase()
					.includes(query?.toLowerCase().split(" ").join("")) ||
				item?.f_name
					.toLowerCase()
					.includes(query?.toLowerCase().split(" ").join("")) ||
				item?.l_name
					.toLowerCase()
					.includes(query?.toLowerCase().split(" ").join("")) ||
				name.toLowerCase().includes(query?.toLowerCase())
			)
		})
		setFilteredUsers(results)
	}, [allUsers, query])

	return (
		<>
			{query && query.length > 0 ? (
				<ul className="search-results">
					{filteredUsers &&
						filteredUsers.length > 0 &&
						filteredUsers.map((user) => (
							<ResultUser
								key={user.id}
								user={user}
								handleOnClick={handleOnClick}
							/>
						))}
				</ul>
			) : (
				<>
					<h3>Recent User Searches</h3>
					<ul className="search-results reversed">
						{recentUsers &&
							recentUsers.length > 0 &&
							recentUsers.map((user) => (
								<ResultUser
									key={user.id}
									user={user}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
