import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import BottomMenu from "../BottomMenu"

const DrinkNav = () => {
	const navigate = useNavigate()
	function handleCancel() {
		navigate(-1)
	}

	const [menuIsActive, setMenuIsActive] = useState(false)

	let links = [
		{
			label: "Edit",
			link: "./edit",
		},
	]

	return (
		<>
			<div className="drink-nav">
				<div className="cancel" onClick={(e) => handleCancel(e)}>
					<FontAwesomeIcon icon={`fas fa-arrow-left`} />
				</div>
				<div className="menu" onClick={() => setMenuIsActive(!menuIsActive)}>
					<FontAwesomeIcon icon={`fas fa-ellipsis-vertical`} />
				</div>
			</div>
			{/* <BottomMenu
				menuIsActive={menuIsActive}
				setMenuIsActive={setMenuIsActive}
				links={links}
			/> */}
		</>
	)
}

export default DrinkNav
