import separateOut from "./seperateOut"

export default function findTotalSpent(obj, key, subkey) {
	let seperatedObj = separateOut(obj)
	let seperatedObjKeys = seperatedObj[key]

	let merged_keys = []

	seperatedObjKeys.forEach((seperatedObjKey) => {
		merged_keys.push(...seperatedObjKey)
	})

	let result = separateOut(merged_keys)

	result = result[subkey]
	if (!result) return 0
	return result.reduce((sum, number) => {
		return parseFloat(sum) + parseFloat(number)
	}, 0)
}
