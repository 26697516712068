import React, { useEffect, useState } from "react"
import { Outlet, useNavigation } from "react-router-dom"
import { APP_NAME } from ".."
import ExploreNav from "../components/explore/ExploreNav"
import Spinner from "../components/Spinner"
import { RECENT_SEARCHES_STORAGE_KEY } from "../constants/paths"

export default function ExplorePage() {
	const navigation = useNavigation()

	const [storedSearches, setStoredSearches] = useState(null)
	const [query, setQuery] = useState(null)

	function handleSubmit(e) {}

	useEffect(() => {
		document.title = `Explore • ${APP_NAME}`
	}, [])

	const contextData = {
		storedSearches: storedSearches,
		query: query,
	}

	useEffect(() => {
		const storedSearches = JSON.parse(
			localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)
		)
		if (storedSearches) {
			setStoredSearches(storedSearches)
		} else {
			setStoredSearches([])
		}
	}, [])

	return (
		<>
			<ExploreNav
				query={query}
				setQuery={setQuery}
				handleSubmit={handleSubmit}
			/>
			<div className="explore-page main-content">
				<div className="container">
					<main>
						{navigation.state === "loading" ? (
							<Spinner />
						) : (
							<Outlet context={contextData} />
						)}
					</main>
				</div>
			</div>
		</>
	)
}
