import {
	// format,
	isToday,
} from "date-fns"
import React, { useEffect, useState } from "react"
// import { useNavigate } from "react-router-dom"
import mlToL from "../../helpers/mlToL"
// import * as ROUTES from "../../constants/routes"

export const BeerIcon = ({
	percentage,
	amount,
	type,
	currentDate,
	comments,
}) => {
	const [comment, setComment] = useState("Bet you're thirsty!")
	useEffect(() => {
		let comments = [
			"Parched through and through...",
			"A day devoid of delight",
			"Not a drop to quench the thirst",
			"Gasping for a gulp",
			"Drinkless",
		]
		setComment(
			isToday(currentDate)
				? "Thirsty?"
				: comments[Math.floor(Math.random() * comments.length)]
		)
	}, [currentDate])

	let amountW
	switch (type) {
		case "half":
			amountW = `${amount}ml`
			break
		case "full":
			amountW = `${amount} pint${amount !== 1 ? "s" : ""}`
			break
		case "keg":
			amountW = `${amount} keg${amount !== 1 ? "s" : ""} (30L)`
			break
		default:
			amountW = ""
			break
	}

	return (
		<div
			className={`beer ${type}`}
			style={{ cursor: isToday(currentDate) && "pointer" }}>
			{/* {percentage >= 0 && ( */}
			<div className="liquidW">
				<div className="liquid" style={{ height: `${percentage}%` }} />
			</div>
			{/* )} */}
			<div className="glass" />
			<div className="amount">
				{amountW}
				{comments && (
					<div className="comment">{type === "empty" && `${comment}`}</div>
				)}
			</div>
		</div>
	)
}

const BeersIcon = ({ ml, currentDate, comments, title, user }) => {
	// const navigate = useNavigate()

	const pint = 500

	let total_of_kegs = ml / 30000
	let total_of_kegs_exact = Math.floor(total_of_kegs)
	let total_of_pints = ((total_of_kegs - total_of_kegs_exact) * 30000) / pint

	let total_of_pints_exact = Math.floor(total_of_pints)
	let total_of_ml = Math.round((total_of_pints - total_of_pints_exact) * pint)

	const percentage = (total_of_ml / pint) * 100

	// function handleOnClick(e) {
	// 	e.preventDefault()
	// 	if (!user) return
	// 	if (!currentDate) return
	// 	let url = `${ROUTES.HISTORY}/${format(currentDate, "yyyy/MM/dd")}`
	// 	navigate(url)
	// }

	return (
		<div className="beersW">
			<div className="circleW">
				<div className="cicle" />
			</div>
			<div className="title">
				{
					<>
						{`Amount drank ${title ? "at" : ":"}`}
						{title ? (
							<span>{title}</span>
						) : (
							<span>
								{ml > 0
									? mlToL(ml)
									: `nothing${isToday(currentDate) ? " yet" : ""}...`}
							</span>
						)}
					</>
				}
			</div>
			<div
				className="beers"
				// onClick={handleOnClick}
			>
				{ml > 0 ? (
					<>
						{total_of_kegs_exact > 0 && (
							<>
								<BeerIcon
									type="keg"
									amount={total_of_kegs_exact}
									comments={comments}
								/>
								{(total_of_ml > 0 || total_of_pints_exact > 0) && (
									<div className="text">{"+"}</div>
								)}
							</>
						)}
						{total_of_pints_exact > 0 && (
							<>
								<BeerIcon
									type="full"
									amount={total_of_pints_exact}
									percentage={100}
									comments={comments}
								/>
								{total_of_ml > 0 && <div className="text">{"+"}</div>}
							</>
						)}
						{total_of_ml > 0 && (
							<BeerIcon
								type="half"
								amount={total_of_ml}
								percentage={percentage}
								comments={comments}
							/>
						)}
					</>
				) : (
					<BeerIcon
						type="empty"
						percentage={0}
						currentDate={currentDate}
						comments={comments}
					/>
				)}
			</div>
		</div>
	)
}

export default BeersIcon
