import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import {
	Link,
	useLoaderData,
	useNavigate,
	useOutletContext,
} from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { CURRENT_VISIT_STORAGE_KEY } from "../../constants/paths"
import { supabase } from "../../lib/supabaseClient"

export default function AddDrink({ myCurrentVisit, setMyCurrentVisit }) {
	const navigate = useNavigate()
	const data = useLoaderData()
	const user = useOutletContext()

	// console.log(user)

	const [query, setQuery] = useState()
	const [queryError, setQueryError] = useState(false)
	const [drinkId, setDrinkId] = useState()
	const [drinks, setDrinks] = useState([])
	const [selectedDrinks, setSelectedDrinks] = useState([])
	const [searchResults, setSearchResults] = useState([])

	const [quantity, setQuantity] = useState(0)
	const [quantityError, setQuantityError] = useState(false)
	const [price, setPrice] = useState(0)
	const [priceError, setPriceError] = useState(false)

	useEffect(() => {
		myCurrentVisit?.drinks && setSelectedDrinks(myCurrentVisit.drinks)
	}, [myCurrentVisit])

	useEffect(() => {
		setDrinks(data)
	}, [data])

	function handleOnChange(e) {
		// if (!e.target.value) return setSearchResults([])
		setQueryError(false)
		setQuery(e.target.value)
		const resultsArray = drinks.filter((drink) =>
			drink.name.toLowerCase().includes(e.target.value.toLowerCase())
		)
		setSearchResults(resultsArray)
	}

	function handleOnSubmit(e) {
		e.preventDefault()
		if (!drinkId) {
			setQueryError(true)
			return
		}
		if (!quantity > 0) {
			setQuantityError(true)
			return
		}
		if (!price > 0) {
			setPriceError(true)
			return
		}
		let addedDrink = {
			id: uuidv4(),
			drink_id: drinkId,
			timestamp: Math.floor(Date.now() / 1000),
			price: price,
			ml: quantity,
		}
		let updatedObj = (prevMyCurrentVisit) => ({
			...prevMyCurrentVisit,
			drinks: [...selectedDrinks, addedDrink],
		})
		setMyCurrentVisit(updatedObj(myCurrentVisit))
		sessionStorage.setItem(
			CURRENT_VISIT_STORAGE_KEY,
			JSON.stringify(updatedObj(myCurrentVisit))
		)
		let userVisits = user.visits ? user.visits : []
		let updatedUserVisits = userVisits.filter((visit) => {
			return visit.id !== myCurrentVisit.id
		})
		let attendee_ids = myCurrentVisit.attendees
			? myCurrentVisit.attendees.map((a) => a.id)
			: []
		let visitObj = {
			id: myCurrentVisit.id,
			timestamp: myCurrentVisit.timestamp,
			location: myCurrentVisit.location_id,
			attendees: attendee_ids,
			drinks: [...selectedDrinks, addedDrink],
		}
		updatedUserVisits.push(visitObj)
		updateDatabase(updatedUserVisits)
		navigate("../")
	}

	async function updateDatabase(updatedUserVisits) {
		const { error } = await supabase
			.from("profiles")
			.update({ visits: updatedUserVisits })
			.eq("id", user.id)
		if (error) {
			console.error(error)
		}
	}

	const QuickAdd = ({ icon, title, ml }) => {
		return (
			<div
				className="quick-add"
				onClick={() => {
					setQuantityError(false)
					setQuantity(ml)
				}}>
				{icon && (
					<div className="icon">
						<FontAwesomeIcon icon={`fas fa-${icon}`} />
					</div>
				)}
				{title && <div className="title">{title}</div>}
				{ml && <div className="amount">{`(${ml} ml)`}</div>}
			</div>
		)
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div>
			<p>
				<div style={{ cursor: "pointer" }} onClick={() => handleReturn()}>
					<FontAwesomeIcon icon={`arrow-left`} />
				</div>
			</p>
			<h3>What are you drinking?</h3>
			<form method="post" onSubmit={handleOnSubmit} className="form">
				<fieldset>
					<label htmlFor="drink">Your new drink:</label>
					<div className="search-bar">
						<button>
							<FontAwesomeIcon
								icon={`fas fa-${drinkId ? "check" : "search"}`}
							/>
						</button>
						<input
							id="drink"
							autoComplete="off"
							className="input"
							placeholder={`_`}
							type="text"
							name="drink"
							value={query || ""}
							onChange={handleOnChange}
						/>
						<button
							onClick={(e) => {
								e.preventDefault()
								setQuery("")
								setDrinkId()
							}}>
							<FontAwesomeIcon icon={`fas fa-xmark`} />
						</button>
					</div>
					{queryError && <p className="error">Please chose a drink</p>}
					{searchResults && searchResults.length > 0 && query.length > 0 && (
						<div className="search-results">
							<ul>
								{searchResults.map((result) => (
									<li
										key={result.id}
										onClick={() => {
											setQuery(result.name)
											setDrinkId(result.id)
											setSearchResults([])
										}}>
										<div>{result.name}</div>
										<div>
											<small>{result.address}</small>
										</div>
									</li>
								))}
							</ul>
						</div>
					)}
					<input
						name="drink-id"
						value={drinkId || ""}
						onChange={(e) => setDrinkId(e.target.value)}
						style={{ display: "none" }}
					/>
					<p style={{ textAlign: "center" }}>
						<small>
							<Link to={`../new-drink`}>
								Not in the list? <strong>Add it!</strong>
							</Link>
						</small>
					</p>
				</fieldset>

				<fieldset>
					<label htmlFor="quantity">How big is your drink?</label>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "10px",
						}}>
						<input
							id="quantity"
							type="number"
							placeholder="0"
							min={0}
							value={quantity || ""}
							onChange={(e) => {
								setQuantityError(false)
								setQuantity(e.target.value)
							}}
						/>
						<div>
							<b>ml</b>
						</div>
					</div>
					{quantityError && <p className="error">Please add quantity</p>}
				</fieldset>

				<fieldset>
					<p>Quick adds:</p>
					<div className="quick-adds">
						<QuickAdd icon="wine-glass" title="half" ml={250} />
						<QuickAdd icon="wine-bottle" title="bottle" ml={330} />
						<QuickAdd icon="glass-water" title="schooner" ml={425} />
						<QuickAdd icon="beer" title="pint" ml={500} />
					</div>
				</fieldset>

				<fieldset>
					<label htmlFor="cost">How much did it cost?</label>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "10px",
						}}>
						<input
							id="cost"
							type="number"
							placeholder="0"
							min={0}
							step="0.01"
							value={price || ""}
							onChange={(e) => {
								setPriceError(false)
								setPrice(e.target.value)
							}}
						/>
						<div>
							<FontAwesomeIcon icon={"fas fa-euro-sign"} />
						</div>
					</div>
					{priceError && <p className="error">Please add price</p>}
				</fieldset>
				<fieldset>
					<button className="button">Submit</button>
				</fieldset>
			</form>
		</div>
	)
}
