import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function MapStatic({
	lng = 0,
	lat = 0,
	zoom = 13,
	h = 200,
	w = 600,
}) {
	const mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/${lng},${lat},${zoom}/${w}x${h}@2x?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`

	return (
		<div className="map-container">
			<div className="pin">
				<FontAwesomeIcon icon={`fas fa-location-dot`} />
			</div>
			<img src={mapImageUrl} alt="Map" />
		</div>
	)
}
