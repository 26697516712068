import React, { useState } from "react"
import {
	Form,
	useLoaderData,
	useNavigate,
	useNavigation,
} from "react-router-dom"
import DatePicker from "../../components/DatePicker"
import DrinkNav from "../../components/profile/DrinkNav"
import { supabase } from "../../lib/supabaseClient"

export default function EditDrink() {
	const drink = useLoaderData()
	const navigate = useNavigate()
	const navigation = useNavigation()
	const busy = navigation.state === "submitting"

	const [name, setName] = useState(drink.name)
	const [type, setType] = useState(drink.type)
	const [percentage, setPercentage] = useState(drink.alcohol_percentage)

	const handleSubmit = async (e) => {
		e.preventDefault()

		let updated_at = new Date()

		const { error } = await supabase
			.from("drinks")
			.update({
				id: drink.id,
				name,
				type,
				alcohol_percentage: percentage,
				updated_at,
			})
			.eq("id", drink.id)

		if (error) {
			console.error(error)
		} else {
			navigate(-1)
		}
	}

	return (
		<div className="profile-drink">
			<div className="container">
				<DrinkNav drink={drink} />
				<main>
					<Form method="post" onSubmit={handleSubmit}>
						<fieldset>
							<DatePicker />
						</fieldset>
						<fieldset>
							<label htmlFor="name">Drink name:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Name"
								type="text"
								name="name"
								value={name || ""}
								onChange={(e) => setName(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="type">Drink type:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Type"
								type="text"
								name="type"
								value={type || ""}
								onChange={(e) => setType(e.target.value)}
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="percentage">Drink percentage:</label>
							<input
								autoComplete="off"
								className="input"
								placeholder="Percentage"
								type="text"
								name="percentage"
								value={percentage || ""}
								onChange={(e) => setPercentage(e.target.value)}
							/>
						</fieldset>
						<button className="button" disabled={busy}>
							{busy ? "Updating..." : "Update"}
						</button>
					</Form>
				</main>
			</div>
		</div>
	)
}
