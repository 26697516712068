import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"

export default function NewLocation() {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)
	const [type, setType] = useState("pub")
	const [typeResults, setTypeResults] = useState(false)
	const [address, setAddress] = useState("")
	const [addressError, setAddressError] = useState(false)
	const [city, setCity] = useState("London")
	const [cityError, setCityError] = useState(false)
	const [postCode, setPostCode] = useState("")
	const [postCodeError, setPostCodeError] = useState(false)

	let types = ["pub", "bar", "restaurant", "music venue"]

	function handleOnSubmit(e) {
		e.preventDefault()

		if (!name.length > 0) {
			setNameError(true)
			return
		}
		if (!address.length > 0) {
			setAddressError(true)
			return
		}
		if (!city.length > 0) {
			setCityError(true)
			return
		}
		if (!isValidPostcode(postCode)) {
			setPostCodeError(true)
			return
		}

		let newLocation = {
			name: toTitleCase(name),
			type: type,
			address: `${toTitleCase(address)}, ${toTitleCase(city)} ${formatPostcode(
				postCode
			)}`,
		}

		updateDatabase(newLocation)

		navigate(-1)
	}

	async function updateDatabase(newLocation) {
		const { error } = await supabase.from("locations").insert(newLocation)
		if (error) {
			console.error(error)
		}
	}

	function toTitleCase(str) {
		if (typeof str === "undefined") return
		return str.toLowerCase().replace(/(?:^|\s|\/|-)\w/g, function (match) {
			return match.toUpperCase()
		})
	}

	function isValidPostcode(p) {
		var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i
		return postcodeRegEx.test(p)
	}

	function formatPostcode(p) {
		if (isValidPostcode(p)) {
			var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i
			return p.replace(postcodeRegEx, "$1 $2")
		} else {
			return p
		}
	}

	return (
		<div>
			<h3>New location</h3>
			<form onSubmit={handleOnSubmit}>
				<fieldset>
					<label>Name</label>
					<input
						type="text"
						value={name || ""}
						onSelect={() => setNameError(false)}
						onChange={(e) => {
							setName(e.target.value)
						}}
					/>
					{nameError && <p className="error">Please enter a valid name</p>}
				</fieldset>
				<fieldset>
					<label>Type</label>
					<div className="dropdown">
						<input
							className="input"
							type="text"
							name="location"
							value={type || "pub"}
							onChange={(e) => setType(e.target.value)}
							onSelect={() => setTypeResults(true)}
						/>
						<FontAwesomeIcon icon="fas fa-caret-down" />
					</div>
					{typeResults && (
						<div className="search-results">
							<ul>
								{types.map((type, i) => (
									<li
										key={i}
										onClick={() => {
											setType(type)
											setTypeResults(false)
										}}>
										<div>{type}</div>
									</li>
								))}
							</ul>
						</div>
					)}
				</fieldset>
				<fieldset>
					<label>Address</label>
					<input
						type="text"
						value={address || ""}
						onSelect={() => setAddressError(false)}
						onChange={(e) => setAddress(e.target.value)}
					/>
					{addressError && (
						<p className="error">Please enter a valid address</p>
					)}
				</fieldset>
				<fieldset>
					<div className="row">
						<div>
							<label>City</label>
							<input
								type="text"
								value={city || ""}
								onSelect={() => setCityError(false)}
								onChange={(e) => setCity(e.target.value)}
							/>
						</div>
						<div>
							<label>Post Code</label>
							<input
								type="text"
								value={postCode || ""}
								onSelect={() => setPostCodeError(false)}
								onChange={(e) => setPostCode(e.target.value)}
							/>
						</div>
					</div>
					{cityError && <p className="error">Please enter a valid city</p>}
					{postCodeError && (
						<p className="error">Please enter a valid post code</p>
					)}
				</fieldset>
				<fieldset>
					<button className="button">Submit</button>
				</fieldset>
			</form>
		</div>
	)
}
