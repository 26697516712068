import React, { useEffect } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { APP_NAME } from ".."
import SiteNav from "../components/dashboard/SiteNav"

export default function DashboardPage() {
	const user = useOutletContext()

	useEffect(() => {
		document.title = `Dashboard • ${APP_NAME}`
	}, [])
	return (
		<>
			<SiteNav user={user} />
			<div className="page-dashboard main-content">
				<div className="container">
					<main>
						<Outlet context={user} />
					</main>
				</div>
			</div>
		</>
	)
}
