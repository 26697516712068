// import { format, fromUnixTime } from "date-fns"
import React, {
	useEffect,
	useState,
	// useState
} from "react"
import { useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."
import Actions from "../../components/dashboard/Actions"
import BeersIcon from "../../components/profile/Beers"
import findTotalSpent from "../../helpers/findTotalSpent"
// import findTotalSpent from "../../helpers/findTotalSpent"

export default function Dashboard({ myCurrentVisit }) {
	const user = useOutletContext()

	const [currentVisitsToday, setCurrentVisitsToday] = useState([])
	const [currentMl, setCurrentMl] = useState(0)

	useEffect(() => {
		document.title = `Dashboard • ${APP_NAME}`
	}, [])

	useEffect(() => {
		console.log(currentVisitsToday)
	}, [currentVisitsToday])

	useEffect(() => {
		let current = []
		myCurrentVisit && current.push(myCurrentVisit)
		setCurrentVisitsToday(current)
	}, [myCurrentVisit])

	useEffect(() => {
		let ml = 0
		ml =
			currentVisitsToday &&
			currentVisitsToday.length > 0 &&
			findTotalSpent(currentVisitsToday, "drinks", "ml")
		setCurrentMl(ml)
	}, [currentVisitsToday, currentMl])

	return (
		<div>
			<BeersIcon
				comments={true}
				currentDate={Date.now()}
				title={myCurrentVisit ? myCurrentVisit.location_name : false}
				ml={currentMl}
				user={user}
			/>
			{myCurrentVisit && (
				<Actions
					icon="beer"
					title={
						myCurrentVisit.drinks && myCurrentVisit.drinks.length > 0
							? "Had another?"
							: "Had a beer?"
					}
					description="Log it in!"
					link="./add-drink"
				/>
			)}
			{myCurrentVisit && (
				<Actions
					icon={
						myCurrentVisit.attendees && myCurrentVisit.attendees.length > 1
							? "user-friends"
							: "user"
					}
					title={
						myCurrentVisit.attendees && myCurrentVisit.attendees.length > 0
							? `Currently with ${myCurrentVisit.attendees.length} friend${
									myCurrentVisit.attendees.length === 1 ? "" : "s"
							  }`
							: "Add attendees"
					}
					description={
						myCurrentVisit.attendees && myCurrentVisit.attendees.length > 0
							? "Has anyone else joined?"
							: "Currently drinking solo"
					}
					link="./add-attendees"
				/>
			)}
			<Actions
				icon={myCurrentVisit ? `shop` : "hand-point-right"}
				title={
					myCurrentVisit
						? `You're at ${myCurrentVisit.location_name}`
						: "Starting a session?"
				}
				description={myCurrentVisit ? "Moved on?" : "Add your location."}
				link="./add-location"
			/>
		</div>
	)
}
