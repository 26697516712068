import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.scss"
import App from "./App"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faUser,
	faXmark,
	faBeer,
	faArrowLeft,
	faArrowRight,
	faUserFriends,
	faPlus,
	faSackDollar,
	faStore,
	faShop,
	faPen,
	faSearch,
	faWineBottle,
	faChartPie,
	faGlassWater,
	faJar,
	faSterlingSign,
	faUserPlus,
	faCheck,
	faEllipsisVertical,
	faMedal,
	faPercent,
	faLocationDot,
	faTrash,
	faCaretDown,
	faGlobe,
	faCopy,
	faHouse,
	faBars,
	faPaperPlane,
	faShare,
	faClockRotateLeft,
	faStar,
	faHeart,
	faWineGlass,
	faEuroSign,
	faHandPointRight,
	faAngleLeft,
	faAngleRight,
} from "@fortawesome/free-solid-svg-icons"
import {
	faGoogle,
	faInstagram,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons"

library.add(
	faWineGlass,
	faEuroSign,
	faHandPointRight,
	faAngleLeft,
	faAngleRight,
	faArrowLeft,
	faArrowRight,
	faBars,
	faBeer,
	faCaretDown,
	faChartPie,
	faCheck,
	faClockRotateLeft,
	faCopy,
	faEllipsisVertical,
	faGlassWater,
	faGlobe,
	faGoogle,
	faHeart,
	faHouse,
	faInstagram,
	faJar,
	faLocationDot,
	faMedal,
	faPaperPlane,
	faPen,
	faPercent,
	faPlus,
	faSackDollar,
	faSearch,
	faShare,
	faShop,
	faSterlingSign,
	faStar,
	faStore,
	faTrash,
	faTwitter,
	faUser,
	faUserFriends,
	faUserPlus,
	faWineBottle,
	faXmark
)

export const APP_NAME = "Pint?"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
