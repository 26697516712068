import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getUnixTime, startOfTomorrow } from "date-fns"
import React, { useEffect, useState } from "react"
import {
	Link,
	useActionData,
	useLoaderData,
	useNavigate,
} from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { CURRENT_VISIT_STORAGE_KEY } from "../../constants/paths"

export default function AddLocation({ setMyCurrentVisit }) {
	const navigate = useNavigate()
	const actionData = useActionData()
	const data = useLoaderData()

	const [q, setQ] = useState()
	const [error, setError] = useState(false)
	const [locationId, setLocationId] = useState()
	const [locations, setLocations] = useState([])
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		setLocations(data)
	}, [data])

	function handleOnChange(e) {
		setError(false)
		setQ(e.target.value)
		const resultsArray = locations.filter(
			(location) =>
				location.name.toLowerCase().includes(e.target.value.toLowerCase())
			// location.address.toLowerCase().includes(e.target.value.toLowerCase())
		)
		setSearchResults(resultsArray)
	}

	function handleOnSubmit(e) {
		e.preventDefault()
		if (!locationId) {
			setError(true)
			return
		}
		let newObj = {
			id: uuidv4(),
			location_id: locationId,
			location_name: q,
			timestamp: Math.floor(Date.now() / 1000),
			expires: getUnixTime(startOfTomorrow()),
			drinks: [],
		}
		setMyCurrentVisit(newObj)
		sessionStorage.setItem(CURRENT_VISIT_STORAGE_KEY, JSON.stringify(newObj))
		navigate("../")
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div>
			<p>
				<div style={{ cursor: "pointer" }} onClick={() => handleReturn()}>
					<FontAwesomeIcon icon={`arrow-left`} />
				</div>
			</p>
			<h3>Where are you?</h3>
			<form method="post" onSubmit={handleOnSubmit}>
				<fieldset>
					<label htmlFor="location">Your new location:</label>
					<div className="search-bar">
						<button>
							<FontAwesomeIcon
								icon={`fas fa-${locationId ? "check" : "search"}`}
							/>
						</button>
						<input
							id="location"
							autoComplete="off"
							className="input"
							placeholder={`_`}
							type="text"
							name="location"
							value={q || ""}
							onChange={handleOnChange}
						/>
						<button
							onClick={(e) => {
								e.preventDefault()
								setQ("")
								setLocationId()
							}}>
							<FontAwesomeIcon icon={`fas fa-xmark`} />
						</button>
					</div>
				</fieldset>
				{error && <p className="error">Please add location</p>}
				{searchResults && searchResults.length > 0 && q.length > 0 && (
					<div className="search-results">
						<ul>
							{searchResults.map((result, i) => {
								return (
									i < 5 && (
										<li
											key={result.id}
											onClick={() => {
												setQ(result.name)
												setLocationId(result.id)
												setSearchResults([])
											}}>
											<div>{result.name}</div>
											<div>
												<small>{result.address}</small>
											</div>
										</li>
									)
								)
							})}
						</ul>
					</div>
				)}
				<fieldset>
					<input
						name="location-id"
						value={locationId || ""}
						onChange={(e) => setLocationId(e.target.value)}
						style={{ display: "none" }}
					/>
					<button className="button">Submit</button>
					{actionData && actionData.error && (
						<p style={{ color: "red" }}>{actionData.error}</p>
					)}
				</fieldset>
			</form>
			<p style={{ textAlign: "center" }}>
				<Link to={`../new-location`}>
					Not in the list? <strong>Add it</strong>!
				</Link>
			</p>
		</div>
	)
}
