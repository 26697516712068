import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { APP_NAME } from ".."
import Logo from "../components/Logo"
import { DOMAIN_URL } from "../constants/paths"
import * as ROUTES from "../constants/routes"
import { supabase } from "../lib/supabaseClient"
import Spinner from "../components/Spinner"

export default function LoginPage() {
	const navigate = useNavigate()
	const emailRef = useRef()
	const passwordRef = useRef()

	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState("")
	const [emailRequired, setEmailRequired] = useState(false)
	const [password, setPassword] = useState("")
	const [wrongCredentials, setWrongCredentials] = useState(false)

	const handleOnSubmit = async (e) => {
		e.preventDefault()

		try {
			setLoading(true)

			const { error } = await supabase.auth.signInWithPassword({
				email: email,
				password: password,
			})

			if (error) {
				setWrongCredentials(true)
				emailRef.current.select()
				throw error
			}
		} catch (error) {
			setLoading(false)
			// alert(error.error_description || error.message)
		} finally {
			navigate(ROUTES.DASHBOARD)
		}
	}

	async function handleForgotPassword(e) {
		e.preventDefault()

		try {
			setLoading(true)

			const { error } = await supabase.auth.resetPasswordForEmail(email, {
				redirectTo: `${DOMAIN_URL}${ROUTES.UPDATE_PASSWORD}`,
			})

			if (error) {
				setEmailRequired(true)
				emailRef.current.select()
				throw error
			}
		} catch (error) {
			setLoading(false)
		} finally {
			navigate(ROUTES.UPDATE_PASSWORD)
		}
	}

	useEffect(() => {
		if (email.length > 0) {
			setEmailRequired(false)
			setWrongCredentials(false)
		}
	}, [email])

	useEffect(() => {
		document.title = `Login • ${APP_NAME}`
	}, [])

	return (
		<div className="auth-page main-content">
			<div className="container">
				{loading ? (
					<Spinner />
				) : (
					<>
						<Logo />
						<h1>Login</h1>
						<div>
							<form onSubmit={(e) => handleOnSubmit(e)}>
								<fieldset>
									<input
										ref={emailRef}
										className={
											emailRequired || wrongCredentials ? "input-error" : ""
										}
										type="email"
										placeholder="Email"
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</fieldset>
								{emailRequired && <p className="error">Email required</p>}
								<fieldset>
									<input
										ref={passwordRef}
										className={wrongCredentials ? "input-error" : ""}
										type="password"
										placeholder="Password"
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<p className="link">
										<span onClick={(e) => handleForgotPassword(e)}>
											Forgot password?
										</span>
									</p>
								</fieldset>
								<button type="submit" className="button">
									<span>Login</span>
								</button>
								{wrongCredentials && (
									<p className="error">
										{`Email not confirmed`}
										<br />
										{`or wrong credentials`}
									</p>
								)}
							</form>
							<p>
								<Link to={ROUTES.SIGN_UP}>Need an account?</Link>
							</p>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
