import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import {
	// Link,
	useLoaderData,
	useNavigate,
	// useOutletContext,
} from "react-router-dom"
import AvatarIcon from "../../components/AvatarIcon"
import ResultUser from "../../components/ResultUser"
import { CURRENT_VISIT_STORAGE_KEY } from "../../constants/paths"
import aToZ from "../../helpers/aToZ"

export default function AddAttendees({ myCurrentVisit, setMyCurrentVisit }) {
	const navigate = useNavigate()

	// const [currentVisit, setCurrentVisit] = useOutletContext()
	const [q, setQ] = useState()
	const [attendeeId, setAttendeeId] = useState("")
	const [attendees, setAttendees] = useState([])
	const [selectedAttendees, setSelectedAttendees] = useState([])
	const [searchResults, setSearchResults] = useState([])
	const data = useLoaderData()

	useEffect(() => {
		myCurrentVisit?.attendees && setSelectedAttendees(myCurrentVisit.attendees)
	}, [myCurrentVisit])

	useEffect(() => {
		setAttendees(aToZ(data))
	}, [data])

	function handleOnChange(e) {
		setQ(e.target.value)
		let filteredArr = attendees.filter(
			(x) => !selectedAttendees.some((y) => y.id === x.id)
		)
		let resultsArray = filteredArr.filter(
			(attendee) =>
				attendee.f_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
				attendee.l_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
				attendee.username.toLowerCase().includes(e.target.value.toLowerCase())
		)
		setSearchResults(resultsArray)
	}

	function handleOnClick(result) {
		setQ("")
		setAttendeeId(result.id)
		setSelectedAttendees([...selectedAttendees, result])
		setSearchResults([])
	}

	function handleSubmit(e) {
		e.preventDefault()
		let updatedObj = (prevMyCurrentVisit) => ({
			...prevMyCurrentVisit,
			attendees: selectedAttendees,
		})

		setMyCurrentVisit(updatedObj(myCurrentVisit))
		sessionStorage.setItem(
			CURRENT_VISIT_STORAGE_KEY,
			JSON.stringify(updatedObj(myCurrentVisit))
		)
		navigate("../")
	}

	function handleReturn() {
		navigate(-1)
	}

	function removeSelectedAttendee(id) {
		setSelectedAttendees(selectedAttendees.filter((a) => a.id !== id))
	}

	return (
		<div>
			<p>
				<div style={{ cursor: "pointer" }} onClick={() => handleReturn()}>
					<FontAwesomeIcon icon={`arrow-left`} />
				</div>
			</p>
			<h3>Who are you with?</h3>
			<form method="post" onSubmit={(e) => handleSubmit(e)}>
				<fieldset>
					{selectedAttendees && selectedAttendees.length > 0 && (
						<>
							<p>Selected attendees:</p>
							<div className="selected-attendees">
								{selectedAttendees.map((selectedAttendee, i) => (
									<div className={`selected-attendee`} key={i}>
										<button
											className="remove"
											onClick={() =>
												removeSelectedAttendee(selectedAttendee.id)
											}>
											<FontAwesomeIcon icon={`fas fa-xmark`} />
										</button>
										<AvatarIcon url={selectedAttendee.avatar} size={50} />
									</div>
								))}
							</div>
						</>
					)}
					<div className="search-bar">
						<button>
							<FontAwesomeIcon icon={`fas fa-search`} />
						</button>
						<input
							autoComplete="off"
							className="input"
							placeholder={``}
							aria-label={`placeholder`}
							type="text"
							name="attendee"
							value={q || ""}
							onChange={handleOnChange}
						/>
						<button
							onClick={(e) => {
								e.preventDefault()
								setQ("")
							}}>
							<FontAwesomeIcon icon={`fas fa-xmark`} />
						</button>
					</div>
					{searchResults && searchResults.length > 0 && q.length > 0 && (
						<div className="search-results">
							<ul>
								{searchResults.map((result) => (
									<ResultUser
										key={result.id}
										user={result}
										handleOnClick={handleOnClick}
									/>
								))}
							</ul>
						</div>
					)}
				</fieldset>
				<fieldset style={{ display: "none" }}>
					<input
						name="attendee-id"
						value={attendeeId || ""}
						onChange={(e) => setAttendeeId(e.target.value)}
					/>
				</fieldset>
				<fieldset>
					<button className="button">Submit</button>
				</fieldset>
			</form>
		</div>
	)
}
