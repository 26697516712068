import React, { useEffect, useState } from "react"
import { supabase } from "../../lib/supabaseClient"
import { useNavigate, useOutletContext } from "react-router-dom"
import AccountsNav from "../../components/accounts/AccountsNav"
import * as ROUTES from "../../constants/routes"
import AvatarIcon from "../../components/AvatarIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function EditProfile() {
	const user = useOutletContext()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [email, setEmail] = useState(null)
	const [username, setUsername] = useState(null)
	const [firstName, setFirstName] = useState(null)
	const [lastName, setLastName] = useState(null)
	const [avatar, setAvatar] = useState(null)

	const [currentAvatar, setCurrentAvatar] = useState(avatar)
	const images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

	const handleNext = () => {
		setCurrentAvatar((prevIndex) =>
			prevIndex + 1 === images.length + 1 ? 1 : prevIndex + 1
		)
	}
	const handlePrevious = () => {
		setCurrentAvatar((prevIndex) =>
			prevIndex - 1 < 1 ? images.length : prevIndex - 1
		)
	}
	useEffect(() => {
		setAvatar(currentAvatar)
	}, [currentAvatar, user])

	useEffect(() => {
		getProfile(user)
		setCurrentAvatar(user.avatar)
	}, [user])

	const getProfile = async (user) => {
		try {
			setLoading(true)

			if (user) {
				setUsername(user.username)
				setEmail(user.email)
				setAvatar(user.avatar)
				setFirstName(user.f_name)
				setLastName(user.l_name)
			}
		} catch (error) {
			alert(error.message)
		} finally {
			setLoading(false)
		}
	}

	const updateProfile = async (e) => {
		e.preventDefault()

		try {
			setLoading(true)

			const updates = {
				id: user.id,
				username: user.username,
				email: user.email,
				avatar: avatar,
				f_name: firstName,
				l_name: lastName,
				updated_at: new Date(),
			}

			let { error } = await supabase.from("profiles").upsert(updates)

			if (error) {
				throw error
			}
		} catch (error) {
			alert(error.message)
		} finally {
			setLoading(false)
			window.location.reload()
			// navigate(-1)
		}
	}

	return (
		<>
			<AccountsNav
				title="Edit Profile"
				onSubmit={updateProfile}
				loading={loading}
			/>
			<div className="accounts-page main-content" aria-live="polite">
				<div className="container">
					<main>
						<div>
							<form onSubmit={updateProfile}>
								<fieldset style={{ textAlign: "center" }}>
									<div className="update-avatar">
										<div className="avatars">
											<div className="arrow" onClick={() => handlePrevious()}>
												<FontAwesomeIcon icon="angle-left" />
											</div>
											{/* <div className="avatar --left">
												<AvatarIcon centered url={currentAvatar - 2} siz10 />
											</div> */}
											{/* <div className="avatar -left">
												<AvatarIcon centered url={currentAvatar} size={70} />
											</div> */}
											<div className="avatar -selected">
												<AvatarIcon centered url={currentAvatar} size={100} />
											</div>
											{/* <div className="avatar -right">
												<AvatarIcon centered url={currentAvatar} size={70} />
											</div> */}
											{/* <div className="avatar --right">
												<AvatarIcon
													centered
													url={currentAvatar + 2}
													size={40}
												/>
											</div> */}
											<div className="arrow " onClick={() => handleNext()}>
												<FontAwesomeIcon icon="angle-right" />
											</div>
										</div>
										<div className="text">Select your avatar</div>
									</div>
								</fieldset>
								<fieldset>
									<div className="row">
										<div>
											<label htmlFor="first-name">First Name</label>
											<input
												id="first-name"
												type="text"
												placeholder="First Name"
												value={firstName || ""}
												onChange={(e) => setFirstName(e.target.value)}
											/>
										</div>
										<div>
											<label htmlFor="last-name">Last Name</label>
											<input
												id="last-name"
												type="text"
												placeholder="Last Name"
												value={lastName || ""}
												onChange={(e) => setLastName(e.target.value)}
											/>
										</div>
									</div>
								</fieldset>

								<fieldset>
									<label htmlFor="username">Username</label>
									<input
										id="username"
										disabled
										type="text"
										placeholder="Username"
										value={username || ""}
										onChange={(e) => setUsername(e.target.value)}
									/>
								</fieldset>
								<fieldset>
									<label htmlFor="email">Email</label>
									<input
										id="email"
										disabled
										type="email"
										placeholder="Email"
										value={email || ""}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</fieldset>
								<fieldset>
									<button className="button">Submit</button>
								</fieldset>
							</form>
							<p style={{ textAlign: "center" }}>
								<button
									onClick={async () => {
										await supabase.auth.signOut()
										navigate(ROUTES.LOGIN)
									}}>
									<b>Sign out</b>
								</button>
							</p>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
