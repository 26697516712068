import React, { useEffect } from "react"
import { APP_NAME } from ".."
import Logo from "../components/Logo"

export default function NotFound() {
	useEffect(() => {
		document.title = `Page Not Found • ${APP_NAME}`
	}, [])

	return (
		<div className="main-content">
			<Logo />
			<h1>Not Found</h1>
		</div>
	)
}
