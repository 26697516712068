import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import {
	Link,
	// useNavigate,
	useOutletContext,
} from "react-router-dom"
import { UserVisitDrink } from "../../classes/UserVisitDrink"
import AvatarIcon from "../../components/AvatarIcon"
import Spinner from "../../components/Spinner"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"

function Drink({ drink, currentDrink }) {
	const newDrink = new UserVisitDrink(drink.timestamp, drink.price, drink.ml)

	return (
		<li className="drink" key={drink.id}>
			{drink.timestamp && <span className="time">{newDrink.time()}</span>}
			{"•"}

			<span className="title">
				<Link to={`${ROUTES.DRINK}/${currentDrink?.id}`}>
					{currentDrink?.name}
				</Link>
			</span>

			{"•"}
			<span className="description">
				{`${newDrink.size()} - ${newDrink.spent()}`}
			</span>
		</li>
	)
}

function Visit({ visit, location, attendees, drinks }) {
	return (
		<div className="visit" key={visit.id}>
			{location && (
				<div className="location">
					<Link to={`${ROUTES.LOCATION}/${location.id}`}>
						<span className="name">{location.name}</span>
					</Link>
					{" - "}
					<span className="date">{format(visit.timestamp * 1000, "H:mm")}</span>
				</div>
			)}
			{attendees.length > 0 && (
				<div className="attendees">
					{attendees.map(
						(attendee) =>
							attendee.avatar && (
								<Link
									key={attendee.id}
									to={`${ROUTES.PROFILE}/${attendee.username}`}>
									<AvatarIcon size={40} url={attendee.avatar} />
								</Link>
							)
					)}
				</div>
			)}
			{drinks.length > 0 && (
				<ul className="drinks">
					{visit.drinks.map((drink) => {
						let currentDrink =
							drinks && drinks.find((item) => item.id === drink.drink_id)
						return (
							<Drink key={drink.id} drink={drink} currentDrink={currentDrink} />
						)
					})}
				</ul>
			)}
		</div>
	)
}

export default function HistoryVisit() {
	// const navigate = useNavigate()
	const { visits } = useOutletContext()

	const [loading, setLoading] = useState(true)
	const [locations, setLocations] = useState([])
	const [attendees, setAttendees] = useState([])
	const [drinks, setDrinks] = useState([])

	useEffect(() => {
		let visitLocations =
			visits && visits.length > 0 && visits.map((v) => v.location)

		let visitAttendees =
			visits && visits.length > 0 && visits.map((v) => v.attendees)
		visitAttendees = visitAttendees.flat(1)
		visitAttendees = [...new Set([...visitAttendees])]

		let visitDrinks = visits && visits.length > 0 && visits.map((v) => v.drinks)
		visitDrinks = visitDrinks.flat(1)
		visitDrinks = visitDrinks.length > 0 && visitDrinks.map((d) => d.drink_id)
		visitDrinks = [...new Set([...visitDrinks])]

		const fetchLocations = async () => {
			const { data, error } = await supabase
				.from("locations")
				.select("id, name")
				.in("id", visitLocations)

			if (error) {
				console.error(error)
			}
			if (data) {
				setLocations(data)
			}
		}

		const fetchAttendees = async () => {
			const { data, error } = await supabase
				.from("profiles")
				.select("id, avatar, username")
				.in("id", visitAttendees)

			if (error) {
				// navigate("/", { replace: true })
				console.error(error)
			}
			if (data) {
				setAttendees(data)
			}
		}

		const fetchDrinks = async () => {
			const { data, error } = await supabase
				.from("drinks")
				.select("id, name")
				.in("id", visitDrinks)

			if (error) {
				// navigate("/", { replace: true })
				console.error(error)
			}
			if (data) {
				setDrinks(data)
			}
		}

		const getVisit = async () => {
			try {
				setLoading(true)
				await fetchLocations()
				await fetchAttendees()
				await fetchDrinks()
			} finally {
				setLoading(false)
			}
		}
		getVisit()
	}, [visits])

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				visits.length > 0 &&
				visits.map((visit, i) => {
					let currentVisitLocation = locations.find(
						(l) => l.id === visit.location
					)

					let currentVisitAttendees = attendees.filter((a) =>
						visit.attendees.includes(a.id)
					)

					return (
						visit.drinks.length > 0 && (
							<Visit
								key={i}
								visit={visit}
								location={currentVisitLocation}
								attendees={currentVisitAttendees}
								drinks={drinks}
							/>
						)
					)
				})
			)}
		</>
	)
}
