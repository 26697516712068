import { format } from "date-fns"

export class UserVisitDrink {
	constructor(timestamp, price, ml) {
		this.timestamp = timestamp
		this.price = price
		this.ml = ml
	}

	spent() {
		return new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: "GBP",
		}).format(this.price)
	}

	size() {
		return `${this.ml}mL`
	}

	time() {
		return format(this.timestamp * 1000, "H:mm")
	}
}
