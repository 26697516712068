export default function aToZ(arr) {
	arr.sort(function (a, b) {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})
	return arr
}
