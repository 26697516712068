import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { APP_NAME } from ".."
// import Logo from "../components/Logo"
import * as ROUTES from "../constants/routes"
import { supabase } from "../lib/supabaseClient"

export default function SignUpPage() {
	const usernameRef = useRef()
	const emailRef = useRef()
	const [usernameTaken, setUsernameTaken] = useState(false)
	const [emailTaken, setEmailTaken] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [isCompleted, setIsCompleted] = useState(false)

	async function createUser(user) {
		let newUserObj = {
			id: user.id,
			updated_at: new Date().toISOString().toLocaleString("zh-TW"),
			username: username,
			f_name: firstName,
			avatar: "",
			l_name: lastName,
			friends: [],
			visits: [],
			drinks: [],
			locations: [],
			email: user.email,
		}

		const { error } = await supabase.from("profiles").insert(newUserObj)
		if (error) {
			console.error(error)
			throw error
		}
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		const user_usernames = await supabase
			.from("profiles")
			.select()
			.eq("username", username)
		const user_emails = await supabase
			.from("profiles")
			.select()
			.eq("email", email)

		if (user_usernames.data.length > 0) {
			setUsernameTaken(true)
			usernameRef.current.select()
		}
		if (user_emails.data.length > 0) {
			setEmailTaken(true)
			emailRef.current.select()
		}
		if (!user_usernames.data.length > 0 && !user_emails.data.length > 0) {
			const { data, error } = await supabase.auth.signUp({
				email: email,
				password: password,
			})
			if (error) {
				throw error
			}
			if (data.user) {
				createUser(data.user)
				setIsCompleted(true)
			}
		}
	}

	useEffect(() => {
		if (username.length > 0) {
			setUsernameTaken(false)
		}
	}, [username])

	useEffect(() => {
		if (email.length > 0) {
			setEmailTaken(false)
		}
	}, [email])

	useEffect(() => {
		document.title = `Sign Up • ${APP_NAME}`
	}, [])

	return (
		<div className="auth-page">
			<div className="container">
				{/* <Logo /> */}
				<h1>Sign up</h1>
				<div>
					{!isCompleted ? (
						<>
							<form onSubmit={(e) => handleOnSubmit(e)}>
								<div style={{ display: "flex", gap: "10px" }}>
									<input
										type="text"
										placeholder="First Name"
										required
										value={firstName || ""}
										onChange={(e) => setFirstName(e.target.value)}
									/>
									<input
										type="text"
										placeholder="Last Name"
										required
										value={lastName || ""}
										onChange={(e) => setLastName(e.target.value)}
									/>
								</div>
								<input
									className={emailTaken ? "error" : ""}
									ref={emailRef}
									type="email"
									placeholder="Email"
									required
									value={email || ""}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{emailTaken && (
									<p
										style={{
											textAlign: "right",
											marginTop: "-15px",
											color: "red",
										}}>
										<small>Email has already been used</small>
									</p>
								)}
								<input
									className={usernameTaken ? "error" : ""}
									ref={usernameRef}
									type="text"
									placeholder="Username"
									required
									value={username || ""}
									onChange={(e) => setUsername(e.target.value)}
								/>
								{usernameTaken && (
									<p
										style={{
											textAlign: "right",
											marginTop: "-15px",
											color: "red",
										}}>
										<small>Username is taken</small>
									</p>
								)}
								<input
									type="password"
									placeholder="Password"
									required
									value={password || ""}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<button className="button">
									<FontAwesomeIcon icon={`fas fa-user-plus`} />
									<span>Sign Up</span>
								</button>
							</form>
							<p>
								<Link to={ROUTES.LOGIN}>Already have an account?</Link>
							</p>
						</>
					) : (
						<p>Please check your emails!</p>
					)}
				</div>
			</div>
		</div>
	)
}
