import React, { useEffect, useState } from "react"
import {
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Route,
	redirect,
	Navigate,
} from "react-router-dom"
import * as ROUTES from "./constants/routes"
import DashboardPage from "./pages/dashboard"
import LoginPage from "./pages/login"
import NotFound from "./pages/not-found"
import SignUpPage from "./pages/sign-up"
import UpdatePasswordPage from "./pages/update-password"
import { supabase } from "./lib/supabaseClient"
import EditProfile from "./pages/accounts/edit"
import Root from "./routes/root"
import ExplorePage from "./pages/explore"
import ExploreUsers from "./pages/explore/users"
import ExplorePlaces from "./pages/explore/places"
import ExploreDrinks from "./pages/explore/drinks"
import ProfileUser from "./pages/explore/profile-user"
import LocationProfile from "./pages/explore/profile-location"
import DrinkProfile from "./pages/explore/profile-drink"
import AddLocation from "./pages/dashboard/add-location"
import Dashboard from "./pages/dashboard/dashboard"
import AddDrink from "./pages/dashboard/add-drink"
import AddAttendees from "./pages/dashboard/add-attendees"
import NewLocation from "./pages/dashboard/new-location"
import { CURRENT_VISIT_STORAGE_KEY } from "./constants/paths"
import NewDrink from "./pages/dashboard/new-drink"
import History from "./pages/accounts/history"
import HistoryVisit from "./pages/accounts/visit"
// import { format } from "date-fns"
import Profile from "./pages/profile"
import EditLocation from "./pages/explore/edit-location"
import EditDrink from "./pages/explore/edit-drink"
import EditVisit from "./pages/accounts/edit-visit"
import { format } from "date-fns"

export default function App() {
	const [myCurrentVisit, setMyCurrentVisit] = useState()

	useEffect(() => {
		const storedVisit = JSON.parse(
			sessionStorage.getItem(CURRENT_VISIT_STORAGE_KEY)
		)
		if (storedVisit) {
			if (storedVisit.expires <= storedVisit.timestamp) {
				setMyCurrentVisit()
			} else {
				setMyCurrentVisit(storedVisit)
			}
		} else {
			setMyCurrentVisit()
		}
	}, [])

	let today = new Date()
	today = today.getTime()
	let todayURL = format(today, "yyyy/MM/dd")

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				{/* AUTH */}
				<Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
				<Route path={ROUTES.LOGIN} element={<LoginPage />} />
				<Route path={ROUTES.UPDATE_PASSWORD} element={<UpdatePasswordPage />} />

				<Route
					element={<Root />}
					path={"/"}
					errorElement={<NotFound />}
					loader={async () => {
						const { data: user } = await supabase.auth.getUser()

						if (!user.user) {
							return redirect(ROUTES.LOGIN)
						} else {
							const { data } = await supabase
								.from("profiles")
								.select()
								.eq("id", user.user.id)
								.single()

							return data
						}
					}}>
					<Route path={`:username`} element={<Profile />} />

					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("profiles")
								.select()
								.eq("username", params.username)
								.single()
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								return data
							}
						}}
						path={`${ROUTES.PROFILE}/:username`}
						element={<ProfileUser />}
					/>

					<Route index element={<Navigate to={ROUTES.DASHBOARD} replace />} />
					<Route element={<DashboardPage />} path={ROUTES.DASHBOARD}>
						<Route
							element={<Dashboard myCurrentVisit={myCurrentVisit} />}
							index
						/>
						<Route
							element={<AddLocation setMyCurrentVisit={setMyCurrentVisit} />}
							path={"add-location"}
							loader={async () => {
								const { data } = await supabase
									.from("locations")
									.select("name, id, address")
									.order("name", { ascending: true })
								return data
							}}
							// action={addLocationAction}
						/>
						<Route element={<NewLocation />} path={"new-location"} />
						<Route
							element={
								<AddDrink
									myCurrentVisit={myCurrentVisit}
									setMyCurrentVisit={setMyCurrentVisit}
								/>
							}
							path={"add-drink"}
							loader={async () => {
								const { data } = await supabase
									.from("drinks")
									.select("name, id, alcohol_percentage, type")
									.order("name", { ascending: true })
								return data
							}}
						/>
						<Route element={<NewDrink />} path={"new-drink"} />
						<Route
							element={
								<AddAttendees
									myCurrentVisit={myCurrentVisit}
									setMyCurrentVisit={setMyCurrentVisit}
								/>
							}
							path={"add-attendees"}
							loader={async () => {
								const { data } = await supabase
									.from("profiles")
									.select("username, id, f_name, l_name, avatar")
								return data
							}}
						/>
					</Route>
					<Route path={"accounts"}>
						<Route path={"edit"} element={<EditProfile />} />
						<Route
							path={`${ROUTES.HISTORY}`}
							element={<History />}
							// errorElement={<Navigate to={todayURL} />}
						>
							<Route
								index
								element={
									<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} replace />
								}
							/>

							<Route
								path=":y/"
								errorElement={
									<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />
								}>
								<Route
									index
									element={
										<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} replace />
									}
								/>
							</Route>
							<Route
								path=":y/:m"
								errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
							/>
							<Route
								path=":y/:m/:d"
								element={<HistoryVisit />}
								errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
							/>
							<Route path=":y/:m/:d/edit" element={<EditVisit />} />
						</Route>
					</Route>
					<Route path={`${ROUTES.EXPLORE}`} element={<ExplorePage />}>
						<Route index element={<Navigate to="users" replace />} />
						<Route
							path={`users`}
							loader={async () => {
								const { data: profiles } = await supabase
									.from("profiles")
									.select("username, id, f_name, l_name, avatar, friends")
									.order("f_name", { ascending: true })

								return {
									profiles: profiles,
								}
							}}
							element={<ExploreUsers />}
						/>
						<Route
							path={`places`}
							loader={async () => {
								const { data: locations } = await supabase
									.from("locations")
									.select("name, id, address")
									.order("name", { ascending: true })

								return {
									locations: locations,
								}
							}}
							element={<ExplorePlaces />}
						/>
						<Route
							path={`beers`}
							loader={async () => {
								const { data: drinks } = await supabase
									.from("drinks")
									.select("name, id, alcohol_percentage, type")
									.order("name", { ascending: true })

								return {
									drinks: drinks,
								}
							}}
							element={<ExploreDrinks />}
						/>
					</Route>
					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("locations")
								.select()
								.eq("id", params.id)
								.single()
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								const coordinates = await fetch(
									`https://api.mapbox.com/geocoding/v5/mapbox.places/$${data.address}.json?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`
								)
									.then((response) => {
										return response.json()
									})
									.then((data) => {
										return data.features[0].center
									})

								return {
									data: data,
									coordinates: coordinates,
								}
							}
						}}
						path={`${ROUTES.LOCATION}/:id`}
						element={<LocationProfile />}
					/>
					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("locations")
								.select()
								.eq("id", params.id)
								.single()
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								return data
							}
						}}
						path={`${ROUTES.LOCATION}/:id/edit`}
						element={<EditLocation />}
					/>

					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("drinks")
								.select()
								.eq("id", params.id)
								.single()
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								return data
							}
						}}
						path={`${ROUTES.DRINK}/:id`}
						element={<DrinkProfile />}
					/>
					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("drinks")
								.select()
								.eq("id", params.id)
								.single()
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								return data
							}
						}}
						path={`${ROUTES.DRINK}/:id/edit`}
						element={<EditDrink />}
					/>
				</Route>
			</>
		)
	)

	return <RouterProvider router={router} />
}
