import separateOut from "./seperateOut"

export default function mergeArrays(obj, key, combine) {
	let seperatedObj = separateOut(obj)
	let seperatedObjKeys = seperatedObj[key]
	let merged = []

	seperatedObjKeys.forEach((seperatedObjKey) => {
		merged.push(...seperatedObjKey)
	})

	let output = []
	!combine ? (output = merged) : (output = [...new Set(merged)])
	return output
}
